import React from "react";
import {observer} from "mobx-react";
import {observable, toJS} from "mobx";
import config from "../../config/main.config";
import Grid from "semantic-ui-react/dist/commonjs/collections/Grid";
import Progress from "semantic-ui-react/dist/commonjs/modules/Progress";
import Button from "semantic-ui-react/dist/commonjs/elements/Button";
import {Icon} from "semantic-ui-react";
import history from "../helper/browserHistory";
import Form from "semantic-ui-react/dist/commonjs/collections/Form";
import {getTokenFromLocalStorage} from "../helper/util";

import Slider from "rc-slider/lib/Slider";
import Checkbox from "semantic-ui-react/dist/commonjs/modules/Checkbox";
import SeparatorRow from "../components/SeparatorRow";
import TransitionablePortal from "semantic-ui-react/dist/commonjs/addons/TransitionablePortal";
import Loader from "semantic-ui-react/dist/commonjs/elements/Loader";
import {withTranslation} from "react-i18next";
import Dropdown from "semantic-ui-react/dist/commonjs/modules/Dropdown";
import ProgressFlower from "../components/ProgressFlower";
import userStore from "../stores/userStore";
import TextArea from "semantic-ui-react/dist/commonjs/addons/TextArea";
import Message from "semantic-ui-react/dist/commonjs/collections/Message";


if (typeof document !== "undefined") {
    // Require scss files
    require('rc-slider/assets/index.css');
}

const LEFT_KEY = 37;
const RIGHT_KEY = 39;
const TAB_KEY = 9;

const initialState = {isLoading: false, results: [], value: ''};

@withTranslation()
@observer
export default class QuestionnairePage extends React.Component {

    @observable
    questionnaire = {
        title: {},
        pages: [],
        state: 1,
        info: {},
        firstPage: {},
    };

    @observable
    products = [];

    @observable
    indications = [];

    @observable
    currentPage = 0;

    @observable
    value = 0;

    @observable
    showUserFeedback = false;

    @observable
    search = initialState;

    @observable
    isAuth = false;
    @observable
    isAuthError = false;

    @observable
    password = "";

    @observable
    started = false;

    @observable
    mandatoryError = false;

    handleAddition(e, {value}, index, pageIndex) {
        this.indications.push({text: value, value})
    };

    handleSearchChange(e, {value}, index, pageIndex) {
        this.questionnaire.pages[pageIndex].questions[index].chosenAnswers = value;
    };

    componentDidMount() {
        let id = this.props.match.params.id;

        if (id !== "0") {
            this.fetchQuestionnaire(id);
            this.fetchProducts(this.props.i18n.language);
        }

        window.scrollTo(0, 0)

        document.addEventListener("keydown", this._handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this._handleKeyDown);
    }

    fetchCachedQuestionnaire(token) {
        fetch(config.BASE_URL + "feedbacks/cached", {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + token,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((resp) => resp.json())
            .then(data => {
                //hhhmm
                    this.addAnswersToQuestionnaire(data.answerQuestions);

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    addAnswersToQuestionnaire(answers) {
        for (let i = 0; i < answers.length; i++) {
            for (let k = 0; k < this.questionnaire.pages.length; k++) {
                for (let j = 0; j < this.questionnaire.pages[k].questions.length; j++) {
                    if (answers[i].questionId === this.questionnaire.pages[k].questions[j].id) {
                        this.questionnaire.pages[k].questions[j].chosenAnswers.push(answers[i].answerId);
                    }
                }
            }
        }
    }

    fetchProducts(language) {
        fetch(config.BASE_URL + "products/" + language + "/all", {
            method: 'GET',
            headers: {
                "Authorization": "Bearer " + getTokenFromLocalStorage(),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then((resp) => resp.json())
            .then(data => {
                let productOptions = [];
                for (let i = 0; i < data.length; i++) {
                    productOptions.push({
                        key: i,
                        value: data[i].id,
                        id: data[i].id,
                        text: data[i].name,
                        title: data[i].name
                    })
                }

                this.products = productOptions;
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    _handleKeyDown = (event) => {
        if (event.keyCode === TAB_KEY) {
            if (this.currentPage !== this.questionnaire.pages.length - 1)
                this.currentPage++;
        }
    };

    fetchQuestionnaire(id) {
        fetch(config.BASE_URL + "questionnaires/" + id, {
            method: "GET",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            }
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                response.json().then(json => {
                    this.isAuth = !json.protected;
                    this.questionnaire = this.addChosenAnswers(json);

                    if (this.questionnaire.firstPage === null) {
                        this.started = true;
                    }
                    //check if continuation
                    let token = getTokenFromLocalStorage();
                    if (token != null) {
                        this.fetchCachedQuestionnaire(token);
                    }
                });

            } else {
                //console.log(response.status)
            }
        }).catch(
            error => {
                //console.log(error);
                if (error.status === 401) {
                    //   history.replace("/"+this.props.i18n.language+"/");
                }
            }
        );
    }

    isMandatoryFilled(questionnaire) {
        // console.log(questionnaire)
        for (let i = 0; i < questionnaire.pages.length; i++) {
            const page = questionnaire.pages[i];
            for (let j = 0; j < page.questions.length; j++) {
                const question = page.questions[j];
                if (question.mandatory && question.chosenAnswers.length === 0) {
                    return false
                }
            }
        }
        return true;
    }

    sendQuestionnaire() {

        let sendObject = toJS(this.questionnaire);
        sendObject.language = this.props.i18n.language;
        if (!this.isMandatoryFilled(sendObject)) {
            this.mandatoryError = true;
            return
        }
        this.showUserFeedback = true;

        let user = userStore.userFromServer;
        if (user !== null) {
            sendObject.userId = user.id;
        }
        window.scrollTo(0, 0);
        fetch(config.BASE_URL + "feedbacks", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*",
            },
            body: JSON.stringify(sendObject)
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                setTimeout(() => {
                    if (this.questionnaire.main === 1) {
                        history.push("/" + this.props.i18n.language + "/overview");
                    } else {
                        if (typeof window !== "undefined") {
                            window.location.replace("https://www.pekana.com/" + this.props.i18n.language + "/");
                        }
                    }
                }, 3000);
            } else {
                alert("Ein Fehler ist passiert");
            }
        }).catch(
            error => {
                //console.log(error);
                if (error.status === 401) {
                    //   history.replace("/"+this.props.i18n.language+"/");
                }
            }
        );
    }

    authQuestionnaire() {
        fetch(config.BASE_URL + "questionnaires/authenticate", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Access-Control-Allow-Origin": "*"
            },
            body: JSON.stringify({
                password: this.password,
                questionnaireId: this.questionnaire.questionnaireId
            })
        }).then(response => {
            if (response.status >= 200 && response.status < 300) {
                this.isAuth = true;
            } else {
                this.isAuthError = false;
                this.setState({});
            }
        }).catch(
            error => {
                //console.log(error);
                if (error.status === 401) {
                    //   history.replace("/"+this.props.i18n.language+"/");
                }
            }
        );
    }

    cacheQuestionnaire() {
        this.questionnaire.state = 2;
        this.sendQuestionnaire();
    }

    addChosenAnswers(questionnaire) {
        for (let j = 0; j < questionnaire.pages.length; j++) {
            for (let i = 0; i < questionnaire.pages[j].questions.length; i++) {
                questionnaire.pages[j].questions[i].chosenAnswers = [];
                if (questionnaire.pages[j].questions[i].type === 4) {
                    questionnaire.pages[j].questions[i].answerOptions = this.indications;
                } else if (questionnaire.pages[j].questions[i].type === 6) {
                    questionnaire.pages[j].questions[i].answerOptions = this.products;
                }
            }
        }
        return questionnaire;
    }

    getCurrentQuestion(question, index, pageIndex, language, i18n) {
        if (!question) {
            return "";
        }
        return <Grid.Row className={"question-row"}>
            <Grid.Column width={16} className={"question-column"}>
                <h2 dangerouslySetInnerHTML={{__html: question.title[language]}}/>
                {question.mandatory === 1 &&
                    <p style={{paddingLeft: 10}}><i>{i18n.t('mandatory')}</i></p>}
            </Grid.Column>
            {this.showAnswerOptions(question.answerOptions, question.type, index, pageIndex, question.chosenAnswers, language)}
        </Grid.Row>

    }

    getCurrentPage(pages, pageIndex, language, i18n) {
        if (pages.length > 0) {
            return pages[pageIndex].questions.map((question, index) => this.getCurrentQuestion(question, index, pageIndex, language, i18n))
        }
        return null;
    }

    getYesNoOption(yes, options, language) {
        if (options[0].title[language] !== "yes") {
            return yes ? options[0].id : options[1].id;
        }
        return yes ? options[1].id : options[0].id;
    }

    getDropdownOptions(options, language) {
        let retOptions = [];
        for (let i = 0; i < options.length; i++) {
            retOptions.push({
                key: i,
                value: options[i].id,
                id: options[i].id,
                text: options[i].title[language],
                title: options[i].title[language]
            })
        }

        return retOptions;
    }

    showAnswerOptions(options, type, questionIndex, pageIndex, chosenAnswers, language) {
        let showOptions = <Grid.Row/>;
        let {t} = this.props;
        switch (type) {
            case 0:
                //Yes/No
                showOptions = [
                    <Form.Field key={"yes"}>
                        <Checkbox
                            label={t("questionnaire.option_yes")}
                            name='radioGroup'
                            checked={chosenAnswers.includes(this.getYesNoOption(true, options, language))}
                            value={this.getYesNoOption(true, options, language)}
                            onChange={(e, {value}) =>
                                chosenAnswers.includes(value) ?
                                    this.questionnaire.pages[pageIndex].questions[questionIndex].chosenAnswers = []
                                    :
                                    this.questionnaire.pages[pageIndex].questions[questionIndex].chosenAnswers = [value]
                            }
                        />
                    </Form.Field>,
                    <Form.Field key={"no"}>
                        <Checkbox
                            label={t("questionnaire.option_no")}
                            name='radioGroup'
                            checked={chosenAnswers.includes(this.getYesNoOption(false, options, language))}
                            value={this.getYesNoOption(false, options, language)}
                            onChange={(e, {value}) =>
                                chosenAnswers.includes(value) ?
                                    this.questionnaire.pages[pageIndex].questions[questionIndex].chosenAnswers = []
                                    :
                                    this.questionnaire.pages[pageIndex].questions[questionIndex].chosenAnswers = [value]
                            }
                        />
                    </Form.Field>
                ];
                break;
            case 1:
                //Single choice List
                showOptions = options.map((option, index) =>
                    <Form.Field key={option.title + index}>
                        <Checkbox
                            label={option.title[language]}
                            name='radioGroup'
                            checked={chosenAnswers.includes(options[index].id)}
                            value={options[index].id}
                            onChange={(e, {value}) =>
                                chosenAnswers.includes(value) ?
                                    this.questionnaire.pages[pageIndex].questions[questionIndex].chosenAnswers = []
                                    :
                                    this.questionnaire.pages[pageIndex].questions[questionIndex].chosenAnswers = [value]
                            }
                        />
                    </Form.Field>);
                break;
            case 2:
                //multiple choice
                showOptions = options.map((option, index) =>
                    <Form.Field key={option.title + index}>
                        <Checkbox
                            label={option.title[language]}
                            checked={chosenAnswers.includes(options[index].id)}
                            value={options[index].id}
                            onChange={(e, {value}) => {
                                let oldAnswers = toJS(this.questionnaire.pages[pageIndex].questions[questionIndex].chosenAnswers);
                                chosenAnswers.includes(value) ?
                                    this.questionnaire.pages[pageIndex].questions[questionIndex].chosenAnswers.splice(oldAnswers.indexOf(value), 1)
                                    :
                                    this.questionnaire.pages[pageIndex].questions[questionIndex].chosenAnswers.push(value)
                            }}
                        />
                    </Form.Field>);
                break;
            case 3:
                //Scale
                showOptions = [
                    <Form.Field key={"scale"} width={16}>
                        <label>{options[0].title[language]}-{options[1].title[language]}</label>
                        <label>{t("questionnaire.scale_value") +
                            (this.questionnaire.pages[pageIndex].questions[questionIndex].chosenAnswers[0] === undefined ?
                                " - "
                                :
                                this.questionnaire.pages[pageIndex].questions[questionIndex].chosenAnswers[0])
                        }</label>
                        <Slider
                            min={options[0].title[language] === "" ? 0 : parseInt(options[0].title[language])}
                            max={options[1].title[language] === "" ? 1 : parseInt(options[1].title[language])}
                            value={chosenAnswers.length === 0 ? parseInt(options[0].title[language]) : parseInt(chosenAnswers[0])}
                            onChange={value => this.questionnaire.pages[pageIndex].questions[questionIndex].chosenAnswers = [value]}/>
                    </Form.Field>];
                break;
            case 4:
                //Indication
                showOptions = [
                    <Form.Field key={"indication"} width={16}>
                        <Dropdown
                            onAddItem={(e, {value}) => this.handleAddition(e, {value}, questionIndex, pageIndex)}
                            onChange={(e, {value}) => this.handleSearchChange(e, {value}, questionIndex, pageIndex)}
                            className={"search-bar"}
                            value={chosenAnswers}
                            placeholder={t("questionnaire.indication")}
                            fluid
                            multiple
                            search
                            selection
                            allowAdditions
                            options={this.indications}
                            additionLabel={t("questionnaire.add_indication")}
                            noResultsMessage={""}
                        />
                        <a href={"https://www.icd-code.de/"} target={"_blank"}>ICD-Codes</a>
                    </Form.Field>];
                break;
            case 5:
                //Text
                showOptions = [
                    <Form.Field key={"text"} width={16}>
                        <TextArea
                            value={chosenAnswers.length === 0 ? "" : chosenAnswers[0]}
                            onChange={(e) => {
                                if (chosenAnswers.length === 0) {
                                    chosenAnswers.push("");
                                }
                                this.questionnaire.pages[pageIndex].questions[questionIndex].chosenAnswers = [e.target.value]
                            }}
                        />

                    </Form.Field>];
                break;
            case 6:
                //Products
                showOptions = [
                    <Form.Field key={"product"} width={16}>
                        <Dropdown
                            placeholder={t("questionnaire.products")}
                            fluid
                            multiple
                            search
                            selection
                            onChange={(e, data) => this.questionnaire.pages[pageIndex].questions[questionIndex].chosenAnswers = data.value}
                            noResultsMessage={t("questionnaire.no_products_found")}
                            options={toJS(this.products)}
                            value={toJS(this.questionnaire.pages[pageIndex].questions[questionIndex].chosenAnswers)}
                        />
                    </Form.Field>];
                break;
            case 7:
                showOptions = [
                    <Form.Field key={"single_choice_drop"} width={16}>
                        <Dropdown
                            placeholder={t('questionnaires_create.single_choice_drop')}
                            fluid
                            search
                            selection
                            onChange={(e, data) => {
                                this.questionnaire.pages[pageIndex].questions[questionIndex].chosenAnswers = [data.value]
                            }}
                            noResultsMessage={t("questionnaire.nothing_found")}
                            options={this.getDropdownOptions(options, language)}
                            value={chosenAnswers.length === 0 ? "" : chosenAnswers[0]}
                        />
                    </Form.Field>];
                break;
            case 8:
                showOptions = [
                    <Form.Field key={"multiple_choice_drop"} width={16}>
                        <Dropdown
                            placeholder={t('questionnaires_create.multiple_choice_drop')}
                            fluid
                            multiple
                            search
                            selection
                            onChange={(e, data) => this.questionnaire.pages[pageIndex].questions[questionIndex].chosenAnswers = data.value}
                            noResultsMessage={t("questionnaire.nothing_found")}
                            options={this.getDropdownOptions(options, language)}
                            value={toJS(this.questionnaire.pages[pageIndex].questions[questionIndex].chosenAnswers)}
                        />
                    </Form.Field>];
                break;
        }

        return <Grid.Column width={16}>
            <Form>
                {showOptions}
            </Form>
        </Grid.Column>;
    }

    render() {
        let {i18n} = this.props;

        return (
            <Grid>
                <Grid.Row centered>
                    <Grid.Column computer={10} tablet={12} mobile={15} textAlign={"center"}>
                        <span className={"curvy-header-big"}>
                            {this.questionnaire.title[i18n.language]}
                        </span>
                    </Grid.Column>
                </Grid.Row>
                <SeparatorRow/>
                {this.started ?
                    this.isAuth ?
                        <Grid.Row centered>
                            <Grid.Column computer={10} tablet={12} mobile={15} textAlign={"center"}>
                                <Grid>
                                    <Grid.Row centered>
                                        <Grid.Column width={16}>
                                            <Progress
                                                value={this.currentPage + 1} total={this.questionnaire.pages.length}
                                                progress='ratio'
                                            />
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row centered>
                                        <Grid.Column width={16}>
                                            {this.getCurrentPage(this.questionnaire.pages, this.currentPage, i18n.language, i18n)}
                                        </Grid.Column>
                                    </Grid.Row>
                                    <Grid.Row centered>
                                        <Grid.Column width={16}>
                                            <Button
                                                className={"questionnaire-button"}
                                                disabled={this.currentPage === 0}
                                                onClick={() => {
                                                    this.currentPage--;
                                                    window.scrollTo(0, 0)
                                                }}
                                                floated='left'
                                                circular
                                                size={"big"}
                                                icon={"arrow left"}>
                                            </Button>
                                            <Button
                                                className={"questionnaire-button"}
                                                disabled={this.currentPage === this.questionnaire.pages.length - 1}
                                                onClick={() => {
                                                    this.currentPage++;
                                                    window.scrollTo(0, 0)
                                                }}
                                                floated='right'
                                                size={"big"}
                                                icon={"arrow right"}
                                                circular/>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {this.mandatoryError &&
                                        <Grid.Row centered>
                                            <Message negative>
                                                <Message.Header> {i18n.t('mandatory_header')}</Message.Header>
                                                <p> {i18n.t('mandatory_text')}</p>
                                            </Message>
                                        </Grid.Row>
                                    }
                                    <Grid.Row centered>
                                        <Grid.Column width={16} textAlign={"center"}>
                                            <Button
                                                type='submit'
                                                className={"questionnaire-button"}
                                                animated='fade'
                                                inverted
                                                disabled={this.currentPage !== this.questionnaire.pages.length - 1}
                                                onClick={() => this.sendQuestionnaire()}>
                                                <Button.Content visible>
                                                    {i18n.t('send').toUpperCase()}
                                                </Button.Content>
                                                <Button.Content hidden>
                                                    <Icon name='check'/>
                                                </Button.Content>
                                            </Button>
                                        </Grid.Column>
                                    </Grid.Row>
                                    {userStore.userFromServer != null ?
                                        <Grid.Row centered>
                                            <Grid.Column width={16} textAlign={"center"}>
                                                <Button
                                                    className={"questionnaire-button"}
                                                    animated='fade'
                                                    inverted
                                                    disabled={this.currentPage === this.questionnaire.pages.length - 1}
                                                    onClick={() => this.cacheQuestionnaire()}>
                                                    <Button.Content visible>
                                                        {i18n.t('cache').toUpperCase()}
                                                    </Button.Content>
                                                    <Button.Content hidden>
                                                        <Icon name='pause'/>
                                                    </Button.Content>
                                                </Button>
                                            </Grid.Column>
                                        </Grid.Row>
                                        : null}
                                </Grid>
                            </Grid.Column>
                            <Grid.Column widescreen={2} computer={3} tablet={3} mobile={3}>
                                <ProgressFlower
                                    percent={this.questionnaire.pages.length > 0 ? (this.currentPage + 1) / this.questionnaire.pages.length : 0}/>
                            </Grid.Column>
                        </Grid.Row>
                        :
                        <Grid.Row centered>
                            <Grid.Column computer={5} tablet={8} mobile={10}>
                                <Form>
                                    <Form.Input
                                        label={i18n.t('questionnaires_overview.password')}
                                        value={this.password}
                                        onChange={(e) => {
                                            this.password = e.target.value;
                                        }}
                                        error={toJS(this.isAuthError)}
                                    />
                                </Form>
                                <Button
                                    className={"questionnaire-button"}
                                    onClick={() => this.authQuestionnaire()}>
                                    {i18n.t('send').toUpperCase()}
                                </Button>
                            </Grid.Column>
                        </Grid.Row>

                    :
                    <Grid.Row centered>
                        <Grid.Column computer={10} tablet={12} mobile={15} textAlign={"center"}>
                            <div dangerouslySetInnerHTML={{__html: this.questionnaire.firstPage[i18n.language]}}/>
                        </Grid.Column>
                        <Grid.Column computer={10} tablet={12} mobile={15} textAlign={"center"}>
                            <Button
                                className={"questionnaire-button"}
                                onClick={() => this.started = true}
                                circular>
                                {i18n.t('questionnaire.start_now')}
                            </Button>
                        </Grid.Column>
                    </Grid.Row>}
                <TransitionablePortal
                    closeOnTriggerClick
                    transition={{animation: 'fly up', duration: 700}}
                    open={this.showUserFeedback}>
                    <div className={"login-fly-up"}>
                        <Grid style={{paddingTop: "10em"}}>
                            <Grid.Row centered>
                                <Grid.Column textAlign={"center"} computer={5} tablet={8} mobile={15}>
                                    <h1 className={"curvy-header-big"}>
                                        {i18n.t('questionnaire.end_title')}
                                    </h1>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row centered>
                                <Grid.Column textAlign={"center"} computer={5} tablet={8} mobile={15}>
                                    <Loader active inline='centered'/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </TransitionablePortal>
            </Grid>
        );
    }

}